@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* Custom keyframes for scrolling text */
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Custom classes */
.animate-scroll {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 60s linear infinite;
}

.header-background {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Solid background when sticky */
  transition: background-color 0.3s ease;
}

/* Fade transition styles */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
