.scrolling-container {
    position: relative;
  }
  
  .scrolling-text {
    display: inline-block;
    white-space: nowrap;
    animation: scroll 30s linear infinite;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
  